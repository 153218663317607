import * as React from 'react';
import { useState } from 'react';
import {
    TextInput,
    ReferenceInput,
    ReferenceArrayInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    SelectInput, 
    useInput, required, ImageInput, ImageField,
    useRecordContext,
    useListContext,
    useEditContext,
    DateTimeInput,
    useNotify
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Grid, IconButton  } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { endpoint } from '../App';

export const AICopywritingTasksInputs = () => {
    const editContext = useEditContext();
    const notify = useNotify();
    const navigate = useNavigate();

    const handleDateTimeChange = (event) => {
        console.log(event.target.value);
        const minDateTime = new Date();
        const selectedTime = new Date(event.target.value);

        if (selectedTime <= minDateTime) {
            notify('Выберите дату и время для публикации в будущем', { type: 'error', undoable: false });
            return new Date(minDateTime.getTime() + 15 * 60 * 1000); 
        }

        return selectedTime;
    };

    return (<Box flex="1" mt={-1}>

        <Box display="flex" flexDirection="row">
			<IconButton
				  onClick={() => navigate(-1)}
				  size="small"
				>
				  <KeyboardBackspaceIcon />
			</IconButton>
			<h3>Новость (статья) к публикации</h3>
		</Box>

        <TextInput label="Тема" source="title" multiline helperText="Полное, избыточное описание о чём должен быть текст, что в нём точно должно быть" fullWidth validate={required()} />

        <Box mt={2}/>
        <TextInput label="Картинка" source="picture_prompt" helperText="Что определённо хотелось бы видеть на картинке (не обязательно, но в помощь к теме)" fullWidth />

        <Grid container spacing={2} mt={1}>
            <Grid item xs={12} sm={6}>
                <TextInput label="Хештеги" source="hashtags" helperText="Хештеги, которые точно надо указать (не обязательно)" fullWidth />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateTimeInput 
                    label="Время публикации" 
                    source="date_time" 
                    validate={required()} 
                    onChange={handleDateTimeChange}
                />
            </Grid>
        </Grid>

        {editContext.record && editContext.record.image_url ? 
            <Box display="flex" flexDirection="column">
                <h5>Сгенерированное изображение:</h5>
                <img src={`${endpoint}${editContext.record.image_url}`} style={{width: '20rem'}} />
            </Box> 
        : null}

        <Box mt={2}/>
        {editContext.record && <TextInput label="Сгенерированный промпт (для генерации картинки)" source="image_prompt" multiline helperText="Написано нейросетью Mistral Large 2.1" fullWidth />}
        <Box mt={2}/>
        {editContext.record && <TextInput label="Сгенерированный контент (для телеграм)" source="content_telegram" multiline helperText="Написано нейросетью Mistral Large 2.1" fullWidth />}
        <Box mt={2}/>
        {editContext.record && <TextInput label="Сгенерированный контент (для Яндекс.Дзен)" source="content_dzen" multiline helperText="Написано нейросетью Mistral Large 2.1" fullWidth />}

    </Box>);
};
