import * as React from 'react';
import {
    List as RaList,
    SimpleListLoading,
    ReferenceField,
    TextField,
    EditButton,
    ShowButton,
    useListContext,
    ExportButton,
    SortButton,
    TopToolbar,
    CreateButton,
    Pagination,
    useGetIdentity,
    BulkActionsToolbar,
    BulkDeleteButton,
    BulkExportButton,
    RecordContextProvider,
    BooleanField,
    DateField,
    BooleanInput,
    Datagrid,
    ImageField,
} from 'react-admin';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Checkbox,
    Typography,
    Box,
    useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { ru } from 'date-fns/locale';
import { Avatar } from '../contacts/Avatar';
import '../crm.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';

const ListContent = () => {
    const {
        data,
        isLoading,
        onToggleItem,
        selectedIds,
    } = useListContext();
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    if (isLoading) {
        return <SimpleListLoading hasLeftAvatarOrIcon hasSecondaryText />;
    }
    const now = Date.now();

    return (
        <>
            <List>
                {data.map(obj => (
                    <RecordContextProvider key={obj.id} value={obj}>
                        <ListItem
                            button
                            component={Link}
                            to={`/AICopywritingTasks/${obj.id}`}
                        >
                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                            <ListItemText
                                primary={`Тема: ${obj.title}`}
                                secondary={`Время публикации: ${new Date(obj.date_time).toLocaleString("RU-ru")}`}
                            />
                            </Box>
                            {isMobile ?  
                            <BooleanField color={obj.published ? '#10793F' : '#313131'} label="Статус публикации" source="published" TrueIcon={CheckCircleIcon} FalseIcon={PendingIcon} ml={1}/>
                            :
                            <Box display="flex" flex={1} justifyContent="start" alignItems="center" sx={{marginRight: '1rem'}}>
                                {obj.published ? 'Опубликовано' : 'Ожидает публикации'} <BooleanField color={obj.published ? '#10793F' : '#313131'} label="Статус публикации" source="published" TrueIcon={CheckCircleIcon} FalseIcon={PendingIcon} ml={1}/>
                            </Box>}
                            <EditButton />
                        </ListItem>
                    </RecordContextProvider>
                ))}
            </List>
        </>
    );
};

const ListActions = () => (
    <TopToolbar>
        <CreateButton
            variant="contained"
            label="Добавить новость"
            sx={{ marginLeft: 2 }}
        />
    </TopToolbar>
);

const AICopywritingTasksList = () => {
    const { identity } = useGetIdentity();
    return identity ? (
        <RaList
            actions={localStorage.getItem('role_id') <= 2 ? <ListActions /> : null}
            perPage={50}
            pagination={<Pagination rowsPerPageOptions={[50, 100, 500, 1000]} />}
            sort={{ field: 'date_time', order: 'DESC' }}
            empty={false}
        >
            <ListContent />
        </RaList>
    ) : null;
};

export default AICopywritingTasksList;


