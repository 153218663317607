import * as React from 'react';
import { Menu, useRedirect, useGetList, } from 'react-admin';
import { Divider, Box, Grid, Paper, Button, useMediaQuery } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import StoreIcon from '@mui/icons-material/Store';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FunnelIcon from '@mui/icons-material/FilterAlt';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import EmailIcon from '@mui/icons-material/Email';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DraftsIcon from '@mui/icons-material/Drafts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddLinkIcon from '@mui/icons-material/AddLink';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
// New icons
import { TbShoppingCart } from "react-icons/tb";
import { FaCartShopping } from "react-icons/fa6";
import { TbCheckbox } from "react-icons/tb";
import { TbBriefcase } from "react-icons/tb";
import { TbUsersGroup } from "react-icons/tb";
import { TbLayoutGridAdd } from "react-icons/tb";
import { TbUserCircle } from "react-icons/tb";
import { TbUsers } from "react-icons/tb";
import { TbFileAnalytics } from "react-icons/tb";
import { TbCashRegister } from "react-icons/tb";

import { FcConferenceCall } from "react-icons/fc";
import { FcBriefcase } from "react-icons/fc";
import { FcAcceptDatabase } from "react-icons/fc";
import { FcBarChart } from "react-icons/fc";
import { FcCustomerSupport } from "react-icons/fc";
import { FcMoneyTransfer } from "react-icons/fc";
import { FcPaid } from "react-icons/fc";
import { FcOrgUnit } from "react-icons/fc";
import { FcCollaboration } from "react-icons/fc";


const MyMenu = (props) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const [isHoveredAccounting, setIsHoveredAccounting] = React.useState(false);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const redirect = useRedirect();

    const { data: funnels,  isLoading: isLoadingFunnels } = useGetList(
        'funnels',
        { 
            pagination: { page: 1, perPage: 5000 },
            sort: { field: 'updated_at', order: 'DESC' },
        }
    );

    const handleMenuItemClick = (id) => {
        redirect(`/requests?funnel_id=${id}`);
        //window.location.reload();
    };

    const handleMouseEnter = () => {
            setIsHovered(true);
    };

    const handleMouseLeave = () => {
            setIsHovered(false);
    };

    const handleMouseEnterAccounting = () => {
        setIsHoveredAccounting(true);
    };

    const handleMouseLeaveAccounting = () => {
        setIsHoveredAccounting(false);
    };

    return (
    <Menu {...props}>
         {/* <Menu.DashboardItem /> */}
        <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Menu.Item to={`/requests`} primaryText="Заявки" leftIcon={<FcPaid size={24} />}/>
        {((document.querySelector('.MuiList-root') && !document.querySelector('.MuiList-root').classList.contains('RaMenu-closed') && isHovered) || isMobile) && (
                <Paper elevation={3} style={{ position: 'relative', top: '0', padding: '10px', maxHeight: isMobile ? '9rem' : null }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    {funnels && funnels.map(funnel => (
                        <Button variant="text" color="primary" style={{ marginBottom: '10px', width: '100%' }} onClick={() => handleMenuItemClick(funnel.id)}>
                            <Box sx={{ textAlign: 'start', width: '100%'}}>{funnel.name}</Box>
                        </Button>
                    ))}
                </Paper>
        )}
        </Box>
        <Menu.Item to="/requestsHistory" primaryText="Задачи" leftIcon={<FcAcceptDatabase size={24} />}/>

        <Menu.Item to="/meetings" primaryText="Встречи" leftIcon={<FcCollaboration size={24} />}/>

        <Menu.Item to="/deals" primaryText="Сделки" leftIcon={<FcBriefcase size={24} />}/>

        {/*<Menu.Item to="/contacts" primaryText="Клиенты" leftIcon={<PeopleIcon />}/>*/}
        <Menu.Item to="/all_requests" primaryText="Клиенты" leftIcon={<FcConferenceCall size={24} />}/>
        <Menu.Item to="/villages" primaryText="Поселки (участки)" leftIcon={<FcOrgUnit size={24} />} />
        <Menu.Item to="/sales" primaryText="Пользователи" leftIcon={<FcCustomerSupport size={24} />}/>
        {!isMobile && <Menu.Item to="/analytics" primaryText="Аналитика" leftIcon={<FcBarChart size={24} />}/>}
        {!isMobile &&
        <Box onMouseEnter={handleMouseEnterAccounting} onMouseLeave={handleMouseLeaveAccounting}>
        <Menu.Item to="/salaries" primaryText="Бухгалтерия" leftIcon={<FcMoneyTransfer size={24} />}/>
        {document.querySelector('.MuiList-root') && !document.querySelector('.MuiList-root').classList.contains('RaMenu-closed') && isHoveredAccounting && (
                <Paper elevation={3} style={{ position: 'relative', top: '0', padding: '10px' }} onMouseEnter={handleMouseEnterAccounting} onMouseLeave={handleMouseLeaveAccounting}>
                        <Button variant="text" color="primary" style={{ marginBottom: '10px', width: '100%' }} onClick={() => redirect(`/salaries`)}>
                            <Box sx={{ textAlign: 'start', width: '100%'}}>Зарплатная ведомость</Box>
                        </Button>
                        <Button variant="text" color="primary" style={{ marginBottom: '10px', width: '100%' }} onClick={() => redirect(`/accounting`)}>
                            <Box sx={{ textAlign: 'start', width: '100%'}}>KPI и премии</Box>
                        </Button>
                        <Button variant="text" color="primary" style={{ marginBottom: '10px', width: '100%' }} onClick={() => redirect(`/debitory`)}>
                            <Box sx={{ textAlign: 'start', width: '100%'}}>Дебиторская задолженность</Box>
                        </Button>
                </Paper>
        )}
        </Box>}

        {/* <Box height={20} component="span" />*/}

        {/* <Menu.Item to="/companies" primaryText="Компании" leftIcon={<StoreIcon />}/> */}
        {/* localStorage.getItem('role_id') <= 2 ? <Menu.Item to="/tags" primaryText="Редактор тегов" leftIcon={<LocalOfferIcon />}/> : null*/}

        
    </Menu>
)
};

export default MyMenu;