import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    required,
    useRedirect,
    useDataProvider,
    DateTimeInput,
    useGetList,
    CreateBase, Form, Toolbar 
} from 'react-admin';
import { Dialog, Grid,  Card, CardContent, Box, Avatar } from '@mui/material';
import { DealInputs } from './DealInputs';
import { Link, useLocation } from 'react-router-dom';

const DealCreate = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const request_id = queryParams.get('request_id');
    const request_name = queryParams.get('request_name') && decodeURIComponent(queryParams.get('request_name'));
    const request_number = queryParams.get('request_number');
    const contact_id = queryParams.get('contact_id');
    const manager_name = queryParams.get('manager_name') && decodeURIComponent(queryParams.get('manager_name'));
    const manager_account_id = queryParams.get('manager_account_id');

    const [currentRequest, setCurrentRequest] = useState();
    const handleRequestChange = (value) => {
        setCurrentRequest(value);
    };

    return (
        <CreateBase
        redirect="list"
        mutationMode="optimistic"
        transform={(data) => ({
            ...data,
            created_at: new Date(),
            ap_id: localStorage.getItem('id'),
            ap_name: localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'),
            request_id: request_id ? request_id : currentRequest.id,
            name: request_name ? request_name : currentRequest.name,
            manager_name: manager_name ? manager_name : currentRequest.manager_name,
            manager_account_id: manager_account_id ? manager_account_id : currentRequest.manager_account_id,
            request_number: request_number ? request_number : currentRequest.number,
            contact_id: contact_id ? contact_id : currentRequest.contact_id
        })}
        >
        <Box mt={2} display="flex">
            <Box flex="1" mb={10}>
                <Form>
                    <Card>
                        <CardContent>
                            <Box>
                                <Box display="flex">
                                    <DealInputs onRequestChange={handleRequestChange} request_id={request_id} request_name={request_name} manager_name={manager_name} />
                                </Box>
                            </Box>
                        </CardContent>
                        <Toolbar />
                    </Card>
                </Form>
            </Box>
        </Box>
        </CreateBase>
    );
};

export default DealCreate;
