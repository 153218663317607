import { Admin, Login } from 'react-admin';
import React from 'react';
import { useLogin } from 'react-admin';
import { TextField, Button, Box } from '@mui/material';
import Logo from '../resources/logo.png';
import { useTheme } from '@mui/material/styles'; 

const MyLoginPage = () => {
  const theme = useTheme();
  const login = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform your login logic here
    const username = e.target.elements.username.value;
    const password = e.target.elements.password.value;

    // Call the login function with the provided credentials
    login({ username, password });
  };

     return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundImage: `url('https://optim.tildacdn.com/tild6332-3436-4130-b535-363834653031/-/format/webp/5a23e10d-a8c5-4d47-b.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
          borderRadius: '8px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div>
          <img src={Logo} alt="CRM Logo" style={{ height: '47px', marginTop: '10px' }} />
        </div>

        <form onSubmit={handleSubmit}>
          <TextField
            id="username"
            name="username"
            label="Имя пользователя"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            sx={{
              color: '#000000',
              '& .MuiInputBase-input': {
                color: '#000000', // Ensure input text is black
              },
              '& .MuiInputLabel-root': {
                color: '#000000', // Ensure label text is black
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#cccccc', // Default border color
                },
                '&:hover fieldset': {
                  borderColor: '#aaaaaa', // Border color when hovering
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.main, // Use MUI primary color when focused
                },
              },
            }}
          />

          <TextField
            id="password"
            name="password"
            label="Пароль"
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            sx={{
              color: '#000000',
              '& .MuiInputBase-input': {
                color: '#000000', // Ensure input text is black
              },
              '& .MuiInputLabel-root': {
                color: '#000000', // Ensure label text is black
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#cccccc', // Default border color
                },
                '&:hover fieldset': {
                  borderColor: '#aaaaaa', // Border color when hovering
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.primary.main, // Use MUI primary color when focused
                },
              },
            }}
          />

          <Button variant="contained" type="submit" fullWidth sx={{ marginTop: 2 }}>
            Войти
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default MyLoginPage;