import React, { useState, useEffect } from 'react';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import {
  Admin, 
  Resource, 
  fetchUtils, 
  useGetIdentity, 
  CustomRoutes, 
  defaultTheme, 
  nanoLightTheme, 
  nanoDarkTheme,
  radiantLightTheme, 
  radiantDarkTheme,
  houseLightTheme, 
  houseDarkTheme,
  Layout,
} from 'react-admin';
import MyAppBar from './design/MyAppBar';
import MyMenu from './design/MyMenu';
import simpleRestProvider from 'ra-data-simple-rest';
import { authProvider } from './authProvider';
import axios from 'axios';
import { omit } from 'lodash';
import { Route, useLocation } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';

import CompanyList from './companies/CompanyList';
import CompaniesCreate from './companies/CompaniesCreate';
import CompaniesEdit from './companies/CompaniesEdit';
import CompaniesShow from './companies/CompaniesShow';

import ContactList from './contacts/ContactList';
import ContactCreate from './contacts/ContactCreate';
import ContactEdit from './contacts/ContactEdit';

import ContragentList from './contragents/ContragentList';
import ContragentCreate from './contragents/ContragentCreate';
import ContragentEdit from './contragents/ContragentEdit';

import AccountList from './accounts/AccountList';
import AccountCreate from './accounts/AccountCreate';
import AccountEdit from './accounts/AccountEdit';
import AccountShow from './accounts/AccountShow';
import WorkShiftList from './accounts/WorkShiftList';
import LunchButtons from './accounts/LunchButtons';

import RequestList from './requests/RequestList';
import RequestCreate from './requests/RequestCreate';
import RequestEdit from './requests/RequestEdit';
import RequestGroupEdit from './requests/RequestGroupEdit';
import RequestListForContacts from './requests/RequestListForContacts';

import RequestIncomeSourceList from './requestIncomeSources/RequestIncomeSourceList';
import RequestIncomeSourceCreate from './requestIncomeSources/RequestIncomeSourceCreate';
import RequestIncomeSourceEdit from './requestIncomeSources/RequestIncomeSourceEdit';

import TaskTypeList from './taskTypes/TaskTypeList';
import TaskTypeCreate from './taskTypes/TaskTypeCreate';
import TaskTypeEdit from './taskTypes/TaskTypeEdit';

import FunnelList from './funnels/FunnelList';
import FunnelCreate from './funnels/FunnelCreate';
import FunnelEdit from './funnels/FunnelEdit';

import VillageList from './villages/VillageList';
import VillageCreate from './villages/VillageCreate';
import VillageEdit from './villages/VillageEdit';
import VillageShow from './villages/VillageShow';

import TaskList from './tasks/TaskList';
import TaskCreate from './tasks/TaskCreate';
import TaskShow from './tasks/TaskShow';
import TaskEdit from './tasks/TaskEdit';

import DealList from './deals/DealList';
import DealCreate from './deals/DealCreate';
import DealEdit from './deals/DealEdit';

import LogsList from './logs/LogsList';
import LogShow from './logs/LogShow';

import NotificationsList from './notifications/NotificationsList';
import Notifications from './notifications/Notifications';

import SanctionsList from './sanctions/SanctionsList';
import SanctionsEdit from './sanctions/SanctionsEdit';

import Dashboard from './dashboard/Dashboard';

import { PopupProvider  } from './design/PopupContext'; 
import MyLoginPage from './design/MyLoginPage';
import SettingsPage from './design/SettingsPage';
import ThemeSettings from './design/ThemeSettings';
import { themes, lightTheme, darkTheme } from './design/themes';

import DocumentEditor from './documents/DocumentEditor';

import AnalyticsPage from './analytics/AnalyticsPage';

import AccountingPage from './accounting/AccountingPage';
import SalariesPage from './accounting/SalariesPage';
import DebitoryPage from './accounting/DebitoryPage';
import DebitoryPayments from './accounting/DebitoryPayments';
import DebitoryActions from './accounting/DebitoryActions';
import AccountingEditForm from './accounting/AccountingEditForm';

import LeadScreenWebsiteList from './leadScreenWebsites/LeadScreenWebsiteList';
import LeadScreenWebsiteCreate from './leadScreenWebsites/LeadScreenWebsiteCreate';
import LeadScreenWebsiteEdit from './leadScreenWebsites/LeadScreenWebsiteEdit';

import MessagesTemplateList from './messagesTemplates/MessagesTemplateList';
import MessagesTemplateCreate from './messagesTemplates/MessagesTemplateCreate';
import MessagesTemplateEdit from './messagesTemplates/MessagesTemplateEdit';

import MeetList from './meetings/MeetList';
import MeetEdit from './meetings/MeetEdit';
import MeetCreate from './meetings/MeetCreate';
import MeetCreatePopup from './meetings/MeetCreatePopup';

import WebsiteList from './websites/WebsiteList';
import WebsiteCreate from './websites/WebsiteCreate';
import WebsiteEdit from './websites/WebsiteEdit';

import WebBlockList from './webBlocks/WebBlockList';
import WebBlockCreate from './webBlocks/WebBlockCreate';
import WebBlockEdit from './webBlocks/WebBlockEdit';

import AICopywritingTasksList from './AICopywritingTasks/AICopywritingTasksList';
import AICopywritingTasksCreate from './AICopywritingTasks/AICopywritingTasksCreate';
import AICopywritingTasksEdit from './AICopywritingTasks/AICopywritingTasksEdit';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const fetchJson = (url, options = {}) => {
    options.user = {
        authenticated: true,
        token: localStorage.getItem('token')
    };
    return fetchUtils.fetchJson(url, options);
};

async function convertFileToBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const arrayBuffer = reader.result;
      resolve(arrayBuffer);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

//export const endpoint = "https://vladeletscrm.ru";//test: "http://89.111.168.7:10001" production: "https://vladeletscrm.ru" https://yusubovcrm.ru https://vladeletscrm.ru
//export const wsEndpoint = "wss://vladeletscrm.ru/ws"; //"wss://vladeletscrm.ru/ws" "wss://yusubovcrm.ru/ws"

export const endpoint = "https://yusubovcrm.ru";
export const wsEndpoint = "wss://yusubovcrm.ru/ws";

//export const endpoint = "https://skadle.com";
//export const wsEndpoint = "wss://skadle.com/ws";

const dataProvider = simpleRestProvider(endpoint, fetchJson);

const MyDataProvider = {
   ...dataProvider,
  create: async (resource, params) => {
    if(resource === "sales" && params.data.picture){
      const filteredObject = omit(params.data, 'picture');

      return convertFileToBase64(params.data.picture).then(picture64 => 
          dataProvider.create(resource, { 
              ...params,
              data : {
                  ...filteredObject,
                  avatar : picture64
              }
          })
      );
    }
    if (resource === "requestsHistory" && params.data.file && params.data.comment_type !== 'task') {
      const formData = new FormData();
      formData.append("comment", params.data.comment);
      formData.append("comment_type", params.data.comment_type);
      formData.append("file", params.data.file.rawFile);
      formData.append("ap_id", localStorage.getItem('id'));
      formData.append("ap_name", localStorage.getItem('first_name') + " " + localStorage.getItem('last_name'));
      formData.append("request_id", params.data.request_id);
      formData.append("request_number", params.data.request_number);
      formData.append("created_at", params.data.created_at);
      formData.append("tableName", "requestsHistory");

      try {
        const response = await fetch(`${endpoint}/upload`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `${localStorage.getItem('token')}`,
          },
        });

        const json = await response.json();
        return { data: json };
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
      }
    }
    if (resource === "villages" && params.data.file){
      const formData = new FormData();
      formData.append("name", params.data.name);
      if(params.data.description){
        formData.append("description", params.data.description);
      }
      if(params.data.address){
        formData.append("address", params.data.address);
      }
      if(params.data.cadastre){
        formData.append("cadastre", params.data.cadastre);
      }
      if(params.data.total_size){
        formData.append("total_size", params.data.total_size);
      }
      if(params.data.number){
        formData.append("number", params.data.number);
      }
      if(params.data.file){
        formData.append("file", params.data.file.rawFile);
      }
      formData.append("ap_id",  params.data.ap_id);
      formData.append("ap_name", params.data.ap_name);
      formData.append("tableName", "villages");
      formData.append("updated_at", params.data.updated_at);
      if (params.data.estates) {
        params.data.estates.forEach((estate, index) => {
            Object.entries(estate).forEach(([key, value]) => {
                formData.append(`estates[${index}][${key}]`, value);
            });
        });
      }

      try {
        const response = await fetch(`${endpoint}/upload`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `${localStorage.getItem('token')}`,
          },
        });

        const json = await response.json();
        return { data: json };
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
      }
    }

    if (resource === "webBlocks" && params.data.file){
      const formData = new FormData();
      formData.append("name", params.data.name);
      formData.append("file", params.data.file.rawFile);
      if(params.data.title){
        formData.append("title", params.data.title);
      }
      if(params.data.text){
        formData.append("text", params.data.text);
      }
      if(params.data.fone_color){
        formData.append("fone_color", params.data.fone_color);
      }
      if(params.data.text_color){
        formData.append("text_color", params.data.text_color);
      }
      formData.append("ap_id",  params.data.ap_id);
      formData.append("ap_name", params.data.ap_name);
      formData.append("tableName", "webBlocks");

      try {
        const response = await fetch(`${endpoint}/upload`, {
          method: "POST",
          body: formData,
          headers: {
            'Authorization': `${localStorage.getItem('token')}`,
          },
        });

        const json = await response.json();
        return { data: json };
      } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
      }
    }

    // Fallback to the default create method for other resources
    return dataProvider.create(resource, params);
  },
    update: async (resource, params) => {
      if(resource === "sales" && params.data.picture){
        const filteredObject = omit(params.data, 'picture');

        return convertFileToBase64(params.data.picture).then(picture64 => 
            dataProvider.update(resource, { 
                ...params,
                data : {
                    ...filteredObject,
                    avatar : picture64
                }
            })
        );
      }
      if (resource === "villages" && params.data.file){
        const formData = new FormData();
        formData.append("entity_id", params.data.id);
        formData.append("name", params.data.name);
        if(params.data.description){
          formData.append("description", params.data.description);
        }
        if(params.data.address){
          formData.append("address", params.data.address);
        }
        if(params.data.cadastre){
          formData.append("cadastre", params.data.cadastre);
        }
        if(params.data.total_size){
          formData.append("total_size", params.data.total_size);
        }
        if(params.data.number){
          formData.append("number", params.data.number);
        }
        if(params.data.file){
          formData.append("file", params.data.file.rawFile);
          formData.append("file_name", params.data.file_name);
          formData.append("file_path", params.data.file_path);
        }
        formData.append("ap_id",  params.data.ap_id);
        formData.append("ap_name", params.data.ap_name);
        formData.append("tableName", "villages");
        formData.append("updated_at", params.data.updated_at);
        if (params.data.estates) {
          params.data.estates.forEach((estate, index) => {
              Object.entries(estate).forEach(([key, value]) => {
                  formData.append(`estates[${index}][${key}]`, value);
              });
          });
        }
  
        try {
          const response = await fetch(`${endpoint}/upload`, {
            method: "PUT",
            body: formData,
            headers: {
              'Authorization': `${localStorage.getItem('token')}`,
            },
          });
  
          const json = await response.json();
          return { data: json };
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      }

      if (resource === "webBlocks" && params.data.file){
        const formData = new FormData();
        formData.append("entity_id", params.data.id);
        formData.append("name", params.data.name);
        formData.append("file", params.data.file.rawFile);
        formData.append("file_name", params.data.file_name);
        formData.append("file_path", params.data.file_path);
        if(params.data.title){
          formData.append("title", params.data.title);
        }
        if(params.data.text){
          formData.append("text", params.data.text);
        }
        if(params.data.fone_color){
          formData.append("fone_color", params.data.fone_color);
        }
        if(params.data.text_color){
          formData.append("text_color", params.data.text_color);
        }
        formData.append("ap_id",  params.data.ap_id);
        formData.append("ap_name", params.data.ap_name);
        formData.append("tableName", "webBlocks");
        formData.append("updated_at", params.data.updated_at);
  
        try {
          const response = await fetch(`${endpoint}/upload`, {
            method: "PUT",
            body: formData,
            headers: {
              'Authorization': `${localStorage.getItem('token')}`,
            },
          });
  
          const json = await response.json();
          return { data: json };
        } catch (error) {
          console.error("Error uploading file:", error);
          throw error;
        }
      }

      return dataProvider.update(resource, params);
    }
};

//const lightTheme = defaultTheme;
//const darkTheme = { ...defaultTheme, palette: { mode: 'dark' } };

const SotkaTheme = {
    /*...defaultTheme,
    palette: {
        primary: {
            main: '#7100e2',
            light: '#7100e2',
            dark: '#7100e2',
            },
        secondary: '#dd8500',
        error: '#ff0000',
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
       // fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },*/
    ...defaultTheme,
    palette: {
        primary: {
          main: '#003801',
        },
        secondary: {
          //main: '#FF8100',
        },
  },
};


function App() {
  const [showFloatingWindow, setShowFloatingWindow] = useState(true);

  return (
    <>
    <Notifications /*goToPage={goToPage}*/ />
    <LunchButtons />
      <Admin 
      dataProvider={MyDataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      //dashboard={Dashboard}
      layout={(props) =>  
      <PopupProvider>
        <Layout {...props} appBar={MyAppBar} menu={MyMenu}>
          <MeetCreatePopup />
          {props.children}
        </Layout>
      </PopupProvider>
      }
      loginPage={MyLoginPage}
      theme={lightTheme}
      darkTheme={darkTheme}
      disableTelemetry
      //theme={localStorage.getItem('theme') ? themes[localStorage.getItem('theme')].lightTheme : themes[0].lightTheme}
      //darkTheme={localStorage.getItem('theme') ? themes[localStorage.getItem('theme')].darkTheme : themes[0].darkTheme}
      >
       <Resource options={{ label: 'Заявки' }} name='requests' list={RequestList} create={RequestCreate} edit={RequestEdit} />
       <Resource options={{ label: 'Задачи' }} name='requestsHistory' list={TaskList} create={TaskCreate} show={TaskShow} edit={TaskEdit} />
       <Resource options={{ label: 'Сделки' }} name='deals' list={DealList} create={DealCreate} edit={DealEdit} />
       <Resource options={{ label: 'Клиенты' }} name='all_requests' list={RequestListForContacts} create={RequestCreate} edit={RequestEdit} />
       <Resource options={{ label: 'Клиенты' }} name='contacts' list={ContactList} create={ContactCreate} edit={ContactEdit} />
       <Resource options={{ label: 'Контрагенты' }} name='contragents' list={ContragentList} create={ContragentCreate} edit={ContragentEdit} />
       <Resource options={{ label: 'Компании' }} name='companies' list={CompanyList} create={CompaniesCreate} edit={CompaniesEdit} show={CompaniesShow} />
       {/*<Resource options={{ label: 'Редактор тегов' }} name='tags' list={TagsPageList} edit={TagEdit} />*/}
       <Resource options={{ label: 'Редактор воронок' }} name='funnels' list={FunnelList} create={FunnelCreate} edit={FunnelEdit} />
       <Resource options={{ label: 'Каналы заявок' }} name='requestIncomeSources' list={RequestIncomeSourceList} create={RequestIncomeSourceCreate} edit={RequestIncomeSourceEdit} />
       <Resource options={{ label: 'Типы задач' }} name='taskTypes' list={TaskTypeList} create={TaskTypeCreate} edit={TaskTypeEdit} />
       <Resource options={{ label: 'Поселки (участки)' }} name='villages' list={VillageList} create={VillageCreate} edit={VillageEdit} show={VillageShow} />
       <Resource options={{ label: 'История изменений' }} name='logs' list={LogsList} show={LogShow} />
       <Resource options={{ label: 'Уведомления' }} name='notifications' list={NotificationsList} />
       <Resource options={{ label: 'Пользователи' }} name='sales' list={AccountList} create={AccountCreate} edit={AccountEdit} show={AccountShow} />
       <Resource options={{ label: 'График работы' }} name='workshift' list={WorkShiftList} create={AccountCreate} edit={AccountEdit} show={AccountShow} />
       <Resource options={{ label: 'Штрафы и санкции' }} name='sanctions' list={SanctionsList} edit={SanctionsEdit} />
       <Resource options={{ label: 'Сервисы карточки лида' }} name='leadScreenWebsites' list={LeadScreenWebsiteList} create={LeadScreenWebsiteCreate} edit={LeadScreenWebsiteEdit} />
       <Resource options={{ label: 'Встречи' }} name='meetings' list={MeetList} create={MeetCreate} edit={MeetEdit} />
       <Resource options={{ label: 'Поступления' }} name='accounting' edit={AccountingEditForm} />
       <Resource options={{ label: 'Шаблоны сообщений' }} name='messagesTemplates' list={MessagesTemplateList} create={MessagesTemplateCreate} edit={MessagesTemplateEdit} />
       <Resource options={{ label: 'Создание сайтов' }} name='w' list={WebsiteList} create={WebsiteCreate} edit={WebsiteEdit} />
       <Resource options={{ label: 'Конструктор блоков' }} name='webBlocks' list={WebBlockList} create={WebBlockCreate} edit={WebBlockEdit} />
       <Resource options={{ label: 'ИИ-копирайтер' }} name='AICopywritingTasks' list={AICopywritingTasksList} create={AICopywritingTasksCreate} edit={AICopywritingTasksEdit} />
       <CustomRoutes>
            <Route options={{ label: 'Настройки' }} path="/settings" element={<SettingsPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Аналитика' }} path="/analytics" element={<AnalyticsPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Премиальные' }} path="/accounting" element={<AccountingPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Зарплатная ведомость' }} path="/salaries" element={<SalariesPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Дебиторская задолженность' }} path="/debitory" element={<DebitoryPage />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Список платежей' }} path="/debitoryPayments" element={<DebitoryPayments />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Действия бухгалтерии' }} path="/debitoryActions" element={<DebitoryActions />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Групповое редактирование заявок' }} path="/request_group_edit" element={<RequestGroupEdit />} />
       </CustomRoutes>
       <CustomRoutes noLayout>
            <Route options={{ label: 'Редактирование документа' }} path="/document_edit" element={<DocumentEditor />} />
       </CustomRoutes>
       <CustomRoutes>
            <Route options={{ label: 'Настройки темы' }} path="/theme_settings" element={<ThemeSettings />} />
       </CustomRoutes>
      </Admin>
      </>
  );
}

export default App;