import React from 'react';
import { Menu } from 'react-admin';
import { Link } from 'react-router-dom';
import BookIcon from '@mui/icons-material/Book';
import FunnelIcon from '@mui/icons-material/FilterAlt';
import AddLinkIcon from '@mui/icons-material/AddLink';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AppsIcon from '@mui/icons-material/Apps';
import MessageIcon from '@mui/icons-material/Message';
import WebIcon from '@mui/icons-material/Web';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { Box, Card, CardContent, Typography, useMediaQuery } from '@mui/material';

const SettingsPage = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const menuStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <Menu style={menuStyle}>
      <Card>
      <CardContent>
      <h2>Настройки CRM-системы</h2>
       { localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/logs" primaryText="История изменений" leftIcon={<BookIcon />} /> : null}
       { localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/funnels" primaryText="Редактор воронок" leftIcon={<FunnelIcon />} /> : null}
       { localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/requestIncomeSources" primaryText="Каналы заявок" leftIcon={<AddLinkIcon />} /> : null}
       { localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/taskTypes" primaryText="Типы задач" leftIcon={<TaskAltIcon />} /> : null}
       { localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/leadScreenWebsites" primaryText={isMobile ? "Сервисы карточки лида" : "Сервисы (сайты), доступные из карточки лида"} leftIcon={<AppsIcon />} /> : null}
       { localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/AICopywritingTasks" primaryText="ИИ-копирайтер новостей" leftIcon={<NewspaperIcon />} /> : null}
       {/* localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/messagesTemplates" primaryText="Шаблоны сообщений клиентам" leftIcon={<MessageIcon />} /> : null*/}
       {/* localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/w" primaryText="Создание сайтов" leftIcon={<WebIcon />} /> : null*/}
       { localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/contragents" primaryText="Контрагенты" leftIcon={<AssignmentIndIcon />} /> : null}
       { localStorage.getItem('role_id') <= 2 ? <Menu.Item component={Link} to="/contacts" primaryText="Данные клиентов" leftIcon={<ContactPageIcon />} /> : null}
      </CardContent>
      </Card>
    </Menu>
  );
};

export default SettingsPage;